import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getArticles, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import { MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import AllCreditCardGuides from '../../components/CreditCards/components/AllCreditCardGuides/AllCreditCardGuides';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IAllCreditCardGuidesTemplateData {
  posts: Queries.WpPostConnection;
}

interface IAllCreditCardGuidesTemplateContext {
  pageNumber: number;
  totalNumberOfPages: number;
}

const AllCreditCardGuidesTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IAllCreditCardGuidesTemplateData, IAllCreditCardGuidesTemplateContext>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = pageContext?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];
  const title = 'All Credit Card Guides';

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const articles = getArticles(posts).map((article) => ({
    ...article,
    imageOverlay: true,
  }));

  const totalNumberOfPages = pageContext?.totalNumberOfPages || 0;
  return (
    <HTPageWrapper category="Credit Cards, Guides" title={title} location={location}>
      <AllCreditCardGuides
        pageHeader={{
          title,
          breadCrumbs: [
            {
              link: '/credit-cards/',
              text: 'Credit Cards',
            },
            {
              link: '/credit-cards/guides/',
              text: 'Credit Card Guides',
            },
            {
              link: '/credit-cards/guides/all/',
              text: 'All Credit Card Guides',
            },
          ],
          subTitle:
            'Discover the best credit cards for every scenario in our guides, and learn how to maximize benefits, unlock perks, and travel smarter with our in-depth, expert advice.',
        }}
        creditCardGuides={articles}
        pagination={{
          currentPageIndex: currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          numberOfPages: totalNumberOfPages,
          basePath: '/credit-cards/guides/all',
        }}
      />
    </HTPageWrapper>
  );
};

export default AllCreditCardGuidesTemplate;

export const Head = ({
  location,
  data,
  pageContext,
}: PageProps<IAllCreditCardGuidesTemplateData, IAllCreditCardGuidesTemplateContext>) => {
  const pageTitle = 'Browse Credit Card Guides from UpgradedPoints.com - Archive';
  const socialTitle = pageTitle;
  const ogDescription =
    'Our experts provide detailed guides on everything from upgraded your card to getting the most points from your purchases.';
  const includeCollectionPageType = true;
  const pageNumber = pageContext?.pageNumber || 0;
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Credit Card Guides', slug: 'guides' },
    { name: 'All Credit Card Guides', slug: 'all' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'All Credit Card Guides - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query AllCreditCardGuides($start: Int, $limit: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: $limit
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
