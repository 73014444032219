import React, { FC } from 'react';
import { IAllCreditCardGuides } from './AllCreditCardGuides.def';
import PageHeader from '../../../PageHeader/PageHeader';
import SquareBrowser from '../../../SquareBrowser/SquareBrowser';
import PaginationV2 from '../../../PaginationV2';

const AllCreditCardGuides: FC<IAllCreditCardGuides> = ({
  pageHeader,
  creditCardGuides,
  pagination,
}) => {
  return (
    <div className="all-credit-card-guides">
      <PageHeader {...pageHeader} />
      <div className="container">
        <SquareBrowser articles={creditCardGuides} />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default AllCreditCardGuides;
